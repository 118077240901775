'use client';

import Heading from '@/components/website/elements/heading';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

const StatisticsData = () => {
  const t = useTranslations('Homepage');
  const statisticData = [
    {
      title: <>{t('yearsExperience')}</>,
      imgFilename: 'bubble-1.png',
    },
    {
      title: <>{t('unlimitedFantasy')}</>,
      imgFilename: 'bubble-2.png',
    },
    {
      title: <>{t('fansOurWork')}</>,
      imgFilename: 'bubble-3.png',
    },
  ];
  return (
    <section className='px-10 flex flex-col lg:flex-row justify-between gap-20 lg:gap-5'>
      {statisticData.map((data, index) => (
        <div key={index} className='lg:w-3/12 flex lg:justify-center items-center'>
          <div className='relative flex items-center max-w-60 gap-2.5'>
            <div className='absolute -top-2.5 left-8 w-24 h-24 rounded-full bg-light-green -z-10' />
            <Image
              src={`/img/bubbles/${data.imgFilename}`}
              width={50}
              height={50}
              alt={`Bubble ${index}`}
              className='max-w-16 w-full h-auto rotate-12'
            />
            <Heading>{data.title}</Heading>
          </div>
        </div>
      ))}
    </section>
  );
};

export default StatisticsData;
