'use client';

import { getLangKey } from '@/lib/utils';
import { ExtendedPortfolio } from '@/root/types';
import { useLocale } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';
import Heading from '../elements/heading';
import MontserratSpan from '../elements/montserrat-span';
import ScrollAnimation from './scroll-animation';

const AnimatedList = ({ items }: { items: ExtendedPortfolio[] }) => {
  const currentLocale = useLocale();
  const langKey = getLangKey(currentLocale);

  return (
    <div className='animated-list flex flex-col gap-20 md:gap-64'>
      {items.length > 0 &&
        items.map((item, index) => {
          const imgSrc = item.images.filter((img) => img.main)[0] ?? item.images[0];
          return (
            <div
              className={`animated-list__item ${index === 0 ? 'mt-16' : ''} flex justify-center items-center`}
              key={index}
            >
              <ScrollAnimation delay={index === 0 ? 300 : 100} className='md:w-full'>
                <Link
                  href={`/${currentLocale}/${item.categoryKey}/${item.slug}`}
                  className='group w-full flex flex-col md:flex-row justify-center items-center gap-5'
                >
                  <picture className='max-w-xl overflow-hidden block' id={'elem1'}>
                    <Image
                      src={imgSrc ? imgSrc.data : ''}
                      alt={item.name[langKey]}
                      width={1080}
                      height={768}
                      quality={100}
                      className='w-full hover:scale-110 ease-in duration-200'
                    />
                  </picture>

                  <div className='w-full md:w-5/12 animated-list__item___info flex flex-col' id={'elem2'}>
                    <MontserratSpan className='text-dark-grey text-sm md:text-lg'>
                      {item.client[langKey]}
                    </MontserratSpan>
                    <Heading className={'group-hover:text-light-green text-4xl md:text-5xl'}>
                      {item.name[langKey]}
                    </Heading>
                  </div>
                </Link>
              </ScrollAnimation>
            </div>
          );
        })}
    </div>
  );
};

export default AnimatedList;
