'use client';

import { scrollToAnchor } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import { Suspense } from 'react';

const TypedText = dynamic(() => import('@/components/website/components/typed-text'));
const MontserratSpan = dynamic(() => import('@/components/website/elements/montserrat-span'));
const ArrowLink = dynamic(() => import('@/components/website/elements/arrow-link'));
const HeroContainer = dynamic(() => import('@/components/website/components/hero-container'));
const Video = dynamic(() => import('@/components/website/elements/video'));

const Hero = () => {
  const t = useTranslations();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    scrollToAnchor('footer');
  };

  return (
    <div className='relative'>
      <div className='absolute left-0 md:left-52 top-0 w-screen md:w-full h-full -mx-5 md:mx-0 -z-10'>
        <Suspense fallback={<p>{t('Common.loading')}</p>}>
          <Video className='object-contain opacity-70' />
        </Suspense>
      </div>
      <HeroContainer>
        <div className='w-full lg:w-9/12 flex flex-col justify-end'>
          <h1 className='text-6xl lg:text-7xl xl:text-[7rem] font-bold'>
            We
            <br />
            always
            <br />
            have{' '}
            <span className='inline-block min-w-[200px]'>
              <TypedText />
            </span>
          </h1>
        </div>
        <div className='w-full min-w-72 lg:w-3/12 flex flex-col gap-8 justify-end'>
          <p>
            <MontserratSpan>{t('Homepage.HeroText')}</MontserratSpan>
          </p>
          <ArrowLink href={'#'} onClick={handleClick} id={'contactUs'}>
            {t('Common.contactUs')}
          </ArrowLink>
        </div>
      </HeroContainer>
    </div>
  );
};

export default Hero;
